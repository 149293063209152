import { useContext } from "react";
import InspectionStore from "app/modules/inspection/inspection.context";
import SettingsStore from "app/modules/settings/settings.context";
import Radio from "app/components/radio/radio";
import { units } from "./mobile-units.modal.model";
import {
  MobileUnitsModalContent,
  MobileUnitsModalContentCloseButton,
  MobileUnitsModalContentHeader,
  MobileUnitsModalContentTitle,
  MobileUnitsModalContentSelectors,
  MobileUnitsModalContentUnitType,
  MobileUnitsModalContentUnitTypeLabel,
  MobileUnitsModalContentUnitTypeList,
  MobileUnitsModalContentUnitTypeListItem,
  MobileUnitsModalWrapper,
} from './mobile-units-modal.style';
import { Unit } from "app/modules/settings/settings.context.d";

/**
 *
 * @returns
 */
const MobileUnitsModal = () => {
  const settingsContext = useContext(SettingsStore);
  const inspectionContext = useContext(InspectionStore);
  const speed = settingsContext.state.speedUnit as Unit;
  const distance = settingsContext.state.distanceUnit as Unit;
  const weather = settingsContext.state.weatherUnit as Unit;
  
  /**
   * map speed li compoenets
   * @returns
   */
  const renderSpeedUnits = () => units.speed.map(({ id, label }) => (
    <MobileUnitsModalContentUnitTypeListItem
      key={id}
      onClick={() => {
        settingsContext.dispatch({ type: 'SET_SPEED_UNIT', data: { id, label } });
      }}
    >
      <Radio selected={speed.id === id} text={label} />
    </MobileUnitsModalContentUnitTypeListItem>
  ));

  /**
   * map distance li compoenets
   * @returns
   */
  const renderDistanceUnits = () => units.distance.map(({ id, label }) => (
    <MobileUnitsModalContentUnitTypeListItem
      key={id}
      onClick={() => {
        settingsContext.dispatch({ type: 'SET_DISTANCE_UNIT', data: { id, label } });      
      }}
    >
      <Radio selected={distance.id === id} text={label} />
    </MobileUnitsModalContentUnitTypeListItem>
  ));

  /**
   * map temperature li compoenets
   * @returns
   */
  const renderWeatherUnits = () => units.weather.map(({ id, label }) => (
    <MobileUnitsModalContentUnitTypeListItem
      key={id}
      onClick={() => {
        settingsContext.dispatch({ type: 'SET_WEATHER_UNIT', data: { id, label } });
      }}
    >
      <Radio selected={weather.id === id} text={label} />
    </MobileUnitsModalContentUnitTypeListItem>
  ));

  /**
   * 
   */
  const handleClose = () => {
    inspectionContext.dispatch({ type: 'MOBILE_TOGGLE_UNITS_MODAL' });
  };

  return (
    <MobileUnitsModalWrapper>
      <MobileUnitsModalContent>
        <MobileUnitsModalContentHeader>
          <MobileUnitsModalContentTitle>
            Units
          </MobileUnitsModalContentTitle>

          <MobileUnitsModalContentCloseButton onClick={handleClose}>
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="16.2637" y="4.94922" width="2" height="16" transform="rotate(45 16.2637 4.94922)" fill="#99A2AD"/>
              <rect x="17.6777" y="16.2637" width="2" height="16" transform="rotate(135 17.6777 16.2637)" fill="#99A2AD"/>
            </svg>
          </MobileUnitsModalContentCloseButton>
        </MobileUnitsModalContentHeader>

        <MobileUnitsModalContentSelectors>
          <MobileUnitsModalContentUnitType>
            <MobileUnitsModalContentUnitTypeLabel>
              Speed
            </MobileUnitsModalContentUnitTypeLabel>
            <MobileUnitsModalContentUnitTypeList>
              {renderSpeedUnits()}
            </MobileUnitsModalContentUnitTypeList>
          </MobileUnitsModalContentUnitType>
          <MobileUnitsModalContentUnitType>
            <MobileUnitsModalContentUnitTypeLabel>
              Distance
            </MobileUnitsModalContentUnitTypeLabel>
            <MobileUnitsModalContentUnitTypeList>
              {renderDistanceUnits()}
            </MobileUnitsModalContentUnitTypeList>
          </MobileUnitsModalContentUnitType>
          <MobileUnitsModalContentUnitType>
            <MobileUnitsModalContentUnitTypeLabel>
              Temperature
            </MobileUnitsModalContentUnitTypeLabel>
            <MobileUnitsModalContentUnitTypeList>
              {renderWeatherUnits()}
            </MobileUnitsModalContentUnitTypeList>
          </MobileUnitsModalContentUnitType>
        </MobileUnitsModalContentSelectors>
      </MobileUnitsModalContent>
    </MobileUnitsModalWrapper>
  );
};

export default MobileUnitsModal;
