import { useContext } from 'react';
import SettingsStore from 'app/modules/settings/settings.context';
import InspectionStore from 'app/modules/inspection/inspection.context';
import Radio from 'app/components/radio/radio';
import { timezoneListModel } from 'app/models/timezones';
import {
  MobileTimezoneModalContent,
  MobileTimezoneModalContentCloseButton,
  MobileTimezoneModalContentCurrentWrapper,
  MobileTimezoneModalContentCurrentTimezone,
  MobileTimezoneModalContentCurrentTimezoneTitle,
  MobileTimezoneModalContentHeader,
  MobileTimezoneModalContentTimezoneList,
  MobileTimezoneModalContentTimezoneListItem,
  MobileTimezoneModalContentTitle,
  MobileTimezoneModalWrapper,
} from './mobile-timezone-modal.style';
import { Timezone } from 'app/modules/settings/settings.context.d';

/**
 *
 * @param props
 * @returns
 */
const MobileTimezoneModal = () => {
  const inspectionContext = useContext(InspectionStore);
  const settingsContext = useContext(SettingsStore);
  const timezone = settingsContext.state.timezone as Timezone;

  /**
   *
   */
  const renderTimezones = () => timezoneListModel.map((tz) => (
    <MobileTimezoneModalContentTimezoneListItem
      key={tz.location}
      onClick={() => {
        const data = { ...tz, id: tz.location, label: tz.name };
        settingsContext.dispatch({ type: 'SET_TIMEZONE_UNIT', data });
      }}
    >
      <Radio text={tz.name} selected={timezone.id === tz.location} />
    </MobileTimezoneModalContentTimezoneListItem>
  ));

  /**
   * 
   */
  const handleClose = () => {
    inspectionContext.dispatch({
      type: 'MOBILE_TOGGLE_TIMEZONE_MODAL',
    });
  };

  return (
    <MobileTimezoneModalWrapper>
      <MobileTimezoneModalContent>
        <MobileTimezoneModalContentHeader>
          <MobileTimezoneModalContentTitle>
            Timezone
          </MobileTimezoneModalContentTitle>

          <MobileTimezoneModalContentCloseButton onClick={handleClose}>
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="16.2637" y="4.94922" width="2" height="16" transform="rotate(45 16.2637 4.94922)" fill="#99A2AD"/>
              <rect x="17.6777" y="16.2637" width="2" height="16" transform="rotate(135 17.6777 16.2637)" fill="#99A2AD"/>
            </svg>
          </MobileTimezoneModalContentCloseButton>
        </MobileTimezoneModalContentHeader>

        <MobileTimezoneModalContentCurrentWrapper>
          <MobileTimezoneModalContentCurrentTimezoneTitle>Current timezone</MobileTimezoneModalContentCurrentTimezoneTitle>
          <MobileTimezoneModalContentCurrentTimezone>{timezone?.name || '-'}</MobileTimezoneModalContentCurrentTimezone>
        </MobileTimezoneModalContentCurrentWrapper>

        <MobileTimezoneModalContentTimezoneList>
          {renderTimezones()}
        </MobileTimezoneModalContentTimezoneList>
      </MobileTimezoneModalContent>
    </MobileTimezoneModalWrapper>
  );
};

export default MobileTimezoneModal;

