import { ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import AccountStore from 'app/modules/account/account.context';
import InspectionStore from 'app/modules/inspection/inspection.context';
import { AuthType } from 'app/modules/account/account.context.d';
import { TrackingPointCascadeType } from 'app/modules/inspection/inspection.interfaces';
import Map from 'app/components/common/map/map';
import Skeleton from 'app/components/skeleton/skeleton';
import MobileObserverView from './components/mobile-observer-view/mobile-observer-view';
import TrackingPointCard from './components/tracking-point-card/tracking-point-card';
import TrackingPointCardWithDevice from './components/tracking-point-card-with-device/tracking-point-card-with-device';
import TrackingPointPassageModal from './components/tracking-point-passage-modal/tracking-point-passage-modal';
import MobileRunStatus from './components/mobile-run-status/mobile-run-status';
import MobileConfigurationButton from './components/mobile-configuration-button/mobile-configuration-button';
import MobileWidgetsButton from './components/mobile-widgets-button/mobile-widgets-button';
import MobileInformationButton from './components/mobile-information-button/mobile-information-button';
import {
  getMapBounds,
  handlePassageChange,
  handlePassageDelete,
  updateMapBounds,
} from './mobile.controller';
import {
  RunMobileContainer,
  MapContainer,
  MobileQubesButton,
  Content,
  CollapseButtonWrapper,
  CollapseButton,
  LaunchButton,
  TrackingPointList,
  ViewTab,
  ViewTabs,
  Views,
  View,
} from './mobile.style';
import TrackingPointOverlay from './components/tracking-point-overlay/tracking-point-overlay';
import TriggerDetailOverlay from './components/trigger-detail-overlay/trigger-detail-overlay';
import MobileTimezoneModal from './components/mobile-timezone-modal/mobile-timezone-modal';
import MobileUnitsModal from './components/mobile-units-modal/mobile-units-modal';
import MobileInformationModal from './components/mobile-information-modal/mobile-information-modal';
import MobileWidgetsModal from './components/mobile-widgets-modal/mobile-widgets-modal';
import TrackingPointMedias from './components/tracking-point-medias/tracking-point-media';
import MobileScheduleModal from './components/mobile-schedule-modal/mobile-schedule-modal';
import MobileQubeModal from './components/mobile-qube-modal/mobile-qube-modal';
import MobileQubeClusterModal from './components/mobile-qube-cluster-modal/mobile-qube-cluster-modal';
import MobileQubesModal from './components/mobile-qubes-modal/mobile-qubes-modal';

/**
 *
 * @param props
 * @returns
 */
const Mobile = () => {
  const inspectionContext = useContext(InspectionStore);
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;

  const listRef = useRef<HTMLDivElement>(null);

  const [fullscreen, setFullscreen] = useState<boolean>(true); // if the map is fullcreen and the list is collapsed
  const [launchLoading, setLaunchLoading] = useState<boolean>(false);
  const [loadded, setLoadded] = useState<boolean>(false);
  const [focusedCard, setFocusedCard] = useState<number>(0);
  const [bounds, setBounds] = useState<google.maps.LatLng[]>([]);

  const nextPointIndex = useMemo(() => {
    if (inspectionContext.run?.trackingpoint_set) {
      const pointid = inspectionContext.run.next_point_id;
      const pointIndex = inspectionContext.run.trackingpoint_set.findIndex(
        (tpoint) => tpoint.id === parseInt(pointid),
      );

      return pointIndex > 0 ? pointIndex : 0;
    }
    
    return 0;
  }, [inspectionContext.run?.next_point_id]);

  const triggerCount = useMemo(() => {
    let count = 0;
    if (!inspectionContext.run?.trackingpoint_set) return count;
    for (const point of inspectionContext.run.trackingpoint_set) {
      count += point.trigger_set?.length || 0;
    }
    return count;
  }, [inspectionContext.run?.trackingpoint_set]);

  /**
   *
   */
  const scrollToCard = (point: TrackingPointCascadeType, delay = 0) => {
    // this set timeout prevent that the query selector wait list card render
    setTimeout(() => {
      // scroll list to focused card
      const item = document.querySelector(`#tp-list-id-${point?.id}`);
      if (item) item.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, delay);
  };

  useEffect(() => {
    inspectionContext.dispatch({
      type: 'SET_IS_FOLLOWING_PIG',
      data: true,
    })
  }, []);

  useEffect(() => {
    if (inspectionContext.run?.launched) {
      setFullscreen(false);
      setLaunchLoading(false);
    }

    if (!inspectionContext.run?.launched) {
      listRef.current?.scrollTo(0, 0);
    }
  }, [inspectionContext.run?.launched]);

  useEffect(() => {
    if (!inspectionContext.run?.trackingpoint_set) {
      return;
    }
      
    const lastIndex = inspectionContext.run.trackingpoint_set?.length - 1;
    const nextPoint = inspectionContext.run.is_finished ? inspectionContext.run.trackingpoint_set[lastIndex] : inspectionContext.run.trackingpoint_set[nextPointIndex];
    if (nextPoint && nextPoint.index && inspectionContext.run.launched && !fullscreen) {
      setFocusedCard(nextPoint.index);

      // scroll list to focused card
      scrollToCard(nextPoint, 600);

      const animationDuration = nextPoint.index * 250 + 350;
      if (!inspectionContext.state.following_pig && typeof google === 'object' && typeof google.maps === 'object') {
        const setBounds = () => {}; // TODO: implement this function
        const setZoom = () => {}; // TODO: implement this function
        updateMapBounds(getMapBounds(nextPoint), setBounds);
        setZoom(); // 16
      }

      setTimeout(() => {
        setLoadded(true);
      }, animationDuration);
    }
  }, [
    fullscreen,
    inspectionContext.run?.launched,
    inspectionContext.run?.pipeline,
    inspectionContext.run?.trackingpoint_set?.[nextPointIndex]?.index,
  ]);

  useEffect(() => {
    if (inspectionContext.state.selected_point) {
      const selected = inspectionContext.state.selected_point;
      setFocusedCard(selected.index || 0);
      scrollToCard(selected);
    }
  }, [inspectionContext.state.selected_point]);

  useEffect(() => {
    if (triggerCount) {
      setFullscreen(false);
    }
  }, [triggerCount]);

  useEffect(() => {
    if (inspectionContext.state.permission_type === 'observer') {
      inspectionContext.dispatch({
        type: 'TOGGLE_VIEW_MODE',
        data: 'observer',
      });
    }
  }, [inspectionContext.state.permission_type]);

  useEffect(() => {
    const focusedCardPoint = inspectionContext.run?.trackingpoint_set?.[focusedCard];
    if (focusedCardPoint) {
      updateMapBounds(getMapBounds(focusedCardPoint), setBounds);
    }
  }, [focusedCard]);

  /**
   *
   * @returns
   */
  const renderTrackingPoints = () => {
    if (!inspectionContext.run?.trackingpoint_set) {
      // create skeletons to loading
      const skeletons: ReactNode[] = [];
      for (let skeletonIndex = 0; skeletonIndex <= 10; skeletonIndex += 1) {
        skeletons.push(<Skeleton key={skeletonIndex} loading height="115px" />);
      }

      return skeletons;
    }

    const tpoints = inspectionContext.run.trackingpoint_set || [];
    const usingDevice = inspectionContext.run.project?.using_triggers;
    const Card = usingDevice ? TrackingPointCardWithDevice : TrackingPointCard;
    return tpoints.map((tpoint, index) => tpoint.id && tpoint._loaded ? (
      <Card
        trackingpoint={tpoint}
        permissionType={inspectionContext.state.permission_type}
        key={tpoint.id}
        focused={index === focusedCard}
        onAddClick={() => {
          inspectionContext.dispatch({
            type: 'SET_SELECTED_POINT_TO_PASSAGE',
            data: tpoint,
          });

          inspectionContext.dispatch({
            type: 'SET_IS_FOLLOWING_PIG',
            data: false,
          });
        }}
        onMoreClick={() => {
          inspectionContext.dispatch({
            type: 'SET_MOBILE_SELECTED_POINT',
            data: tpoint,
          });
        }}
        onPassageDelete={() => {
          inspectionContext.dispatch({
            type: 'SET_IS_FOLLOWING_PIG',
            data: false,
          });

          if (auth) { handlePassageDelete(tpoint, auth.token); }
        }}
        onPassageChange={(tstamp) => {
          inspectionContext.dispatch({
            type: 'SET_IS_FOLLOWING_PIG',
            data: false,
          });

          if (inspectionContext.run) {
            handlePassageChange(tstamp, inspectionContext.run, tpoint, auth.token);
          }
        }}
      />
    ) : (
      <Skeleton key={index} loading height="115px" />
    ));
  };

  return (
    <RunMobileContainer fullscreen={fullscreen}>
      <MapContainer>
        <MobileRunStatus />
        <MobileQubesButton onClick={() => inspectionContext.dispatch({ type: 'MOBILE_QUBES_MODAL', data: true })}>
          <svg width="20" height="20" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.7001 12.1C14.7001 12.404 14.5321 12.668 14.2761 12.804L7.95605 16.356C7.82805 16.452 7.66805 16.5 7.50005 16.5C7.33205 16.5 7.17205 16.452 7.04405 16.356L0.724052 12.804C0.595796 12.7366 0.488462 12.6353 0.41371 12.5112C0.338959 12.3871 0.299649 12.2449 0.300052 12.1V4.9C0.300052 4.596 0.468052 4.332 0.724052 4.196L7.04405 0.644C7.17205 0.548 7.33205 0.5 7.50005 0.5C7.66805 0.5 7.82805 0.548 7.95605 0.644L14.2761 4.196C14.5321 4.332 14.7001 4.596 14.7001 4.9V12.1ZM7.50005 2.22L2.73205 4.9L7.50005 7.58L12.2681 4.9L7.50005 2.22ZM1.90005 11.628L6.70005 14.332V8.964L1.90005 6.268V11.628ZM13.1001 11.628V6.268L8.30005 8.964V14.332L13.1001 11.628Z" fill="white"/>
          </svg>
        </MobileQubesButton>

        {inspectionContext.run ? (
          <>
            <MobileConfigurationButton
              onOpenUnitsModal={() => {
                inspectionContext.dispatch({ type: 'MOBILE_TOGGLE_UNITS_MODAL' });
              }}
              onOpenTimezoneModal={() => {
                inspectionContext.dispatch({ type: 'MOBILE_TOGGLE_TIMEZONE_MODAL' });
              }}
              onOpenDocumentation={() => {

              }}
            />

            <MobileInformationButton
              onClick={() => {
                inspectionContext.dispatch({ type: 'MOBILE_TOGGLE_INFORMATIONS_MODAL' });
              }}
            />

            <MobileWidgetsButton
              onClick={() => {
                inspectionContext.dispatch({ type: 'MOBILE_TOGGLE_WIDGETS_MODAL' });
              }}
            />
          </>
        ) : null}

        <Map
          bounds={bounds}
          zoom={16}
          streetView
          view_mode={inspectionContext.state.view_mode}
          modules={['run']}
          controllers={[
            'labels',
            'follow',
            'users',
            'measure',
            'view',
            'zoom',
            'map-type',
          ]}
        />
      </MapContainer>

      <Content collapsed={fullscreen}>
        {inspectionContext.run && !inspectionContext.run.launched && inspectionContext.state.permission_type === 'editor'? (
          <LaunchButton
            $loading={launchLoading}
            disabled={launchLoading}
            onClick={() => {
              if (launchLoading) return;
              inspectionContext.dispatch({
                type: 'TOGGLE_MODAL',
                data: 'launching',
              });
            }}
          >
            {!launchLoading ? 'Launch' : 'Launching'}
          </LaunchButton>
        ) : null}

        <CollapseButtonWrapper collapsed={fullscreen}>
          <CollapseButton
            collapsed={fullscreen}
            onClick={() => setFullscreen(!fullscreen)}
          >
            <svg
              width="19"
              height="11"
              viewBox="0 0 19 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.5787 0.997244C17.3578 0.996964 17.146 1.0853 16.9902 1.24262L9.58723 8.69089L2.18468 1.24262C1.97438 1.03103 1.66793 0.948446 1.38056 1.02584C1.09333 1.10324 0.868955 1.32899 0.792036 1.61797C0.71511 1.90696 0.797193 2.21545 1.00749 2.42702L8.99871 10.4676C9.15773 10.6189 9.36829 10.7031 9.58724 10.7031C9.80619 10.7031 10.0167 10.6189 10.1759 10.4676L18.1671 2.42702L18.167 2.42716C18.3231 2.26998 18.4107 2.05701 18.4107 1.83489C18.4107 1.61277 18.3231 1.3998 18.167 1.24276C18.0109 1.08572 17.7992 0.997381 17.5785 0.997381L17.5787 0.997244Z"
                fill="white"
              />
            </svg>
          </CollapseButton>
        </CollapseButtonWrapper>

        <ViewTabs $collapsed={fullscreen}>
          <ViewTab
            $active={inspectionContext.state.view_mode === 'editor'}
            onClick={() => {
              inspectionContext.dispatch({
                type: 'TOGGLE_VIEW_MODE',
                data: 'editor',
              });
            }}
          >
            Editor view
          </ViewTab>
          <ViewTab
            $active={inspectionContext.state.view_mode === 'observer'}
            onClick={() => {
              inspectionContext.dispatch({
                type: 'TOGGLE_VIEW_MODE',
                data: 'observer',
              });
            }}
          >
            Observer view
          </ViewTab>
        </ViewTabs>

        <Views $collapsed={fullscreen} >
          <View $view={inspectionContext.state.view_mode || ''}>
            <TrackingPointList
              ref={listRef}
              id="GT_TP_LIST"
              data-testid="gt-test-list"
              onScroll={(e) => {
                if (loadded) {
                  inspectionContext.dispatch({
                    type: 'SET_IS_FOLLOWING_PIG',
                    data: false,
                  });

                  inspectionContext.dispatch({
                    type: 'SET_IS_FOLLOWING_LOGGED_USER',
                    data: false,
                  });
                }

                const updateFocusedCard = _.debounce((event) => {
                  if (inspectionContext.run && inspectionContext.run.trackingpoint_set) {
                    const usingDevice = inspectionContext.run.project?.using_triggers;
                    const cardHeight = usingDevice ? 151 : 115;
                    const padding = 16;

                    // index = round number of pixel scrolled - padding top + padding bottom / height of card + card gap
                    let index = Math.round((event.target.scrollTop + 2 * padding) / (cardHeight + padding));

                    if (event.target.scrollTop + 2 * padding + event.target.clientHeight >= event.target.scrollHeight) {
                      index = inspectionContext.run.trackingpoint_set.length - 1;
                    }

                    setFocusedCard(index);
                  }
                }, 500);
                e.persist();
                updateFocusedCard(e);
              }}
            >
              {renderTrackingPoints()}
            </TrackingPointList>
          </View>
          <View $view={inspectionContext.state.view_mode || ''}>
            <MobileObserverView />
          </View>
        </Views>
      </Content>
    
      {inspectionContext.state.mobile.schedule_modal ? <MobileScheduleModal /> : null}
      {inspectionContext.state.mobile.selected_point_to_passage ? <TrackingPointPassageModal /> : null}
      {inspectionContext.state.mobile.selected_point ? <TrackingPointOverlay /> : null}
      {inspectionContext.state.mobile.selected_point_to_gallery ? <TrackingPointMedias /> : null }
      {inspectionContext.state.mobile.selected_point_to_trigger ? <TriggerDetailOverlay /> : null}
      {inspectionContext.state.mobile.timezone_modal ? <MobileTimezoneModal /> : null}
      {inspectionContext.state.mobile.units_modal ? <MobileUnitsModal /> : null}
      {inspectionContext.state.mobile.informations_modal ? <MobileInformationModal /> : null}
      {inspectionContext.state.mobile.widgets_modal ? <MobileWidgetsModal /> : null}
      {inspectionContext.state.mobile.selected_qube ? <MobileQubeModal /> : null}
      {inspectionContext.state.mobile.qubes_modal && !inspectionContext.state.mobile.selected_qube ? <MobileQubesModal /> : null}
      {inspectionContext.state.mobile.selected_cluster_markers?.length && !inspectionContext.state.mobile.selected_qube ? <MobileQubeClusterModal /> : null}
    </RunMobileContainer>
  );
};

export default Mobile;
